@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

:root {
  --dark-green: #1a2e22;
  --light-green: #DFFFBF;
  --light-grey: #D3D3D3;
  --primary-bg: #072927;
  --primary-text: #FFFFFF;
}

body {
  font-family: 'Poppins', sans-serif;
}

.header {
  position: fixed;
  top: 8px; /* Reduced spacing on mobile */
  left: 8px;
  right: 8px;
  display: flex;
  justify-content: center;
  padding: 0.25rem 0;
  z-index: 1000;
  transition: all 0.3s ease;
}

.header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 16px); /* Adjusted for mobile */
  height: 100%;
  background-color: rgba(134, 153, 114, 0.5);
  opacity: 0;
  border-radius: 9999px;
  transition: opacity 0.7s ease, backdrop-filter 0.7s ease;
  backdrop-filter: blur(0px);
}

.header.scrolled::before {
  opacity: 1;
  backdrop-filter: blur(10px);
}

.header-content {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0.25rem 1rem; /* Reduced padding on mobile */
  z-index: 1;
}

.logo {
  display: flex;
  align-items: center;
  height: 40px; /* Smaller on mobile */
  width: auto;
  overflow: hidden;
}

.logo img {
  height: 32px; /* Smaller on mobile */
  width: auto;
  vertical-align: middle;
  object-fit: contain;
}

/* Mobile menu button */
.menu-toggle {
  display: block; /* Show on mobile */
  background: none;
  border: none;
  color: var(--light-grey);
  font-size: 24px;
  padding: 8px;
  cursor: pointer;
  z-index: 1001;
}

/* Mobile navigation */
.nav-links {
  display: none; /* Hidden by default on mobile */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primary-bg);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 1rem;
  z-index: 999;
}

.nav-links.active {
  display: flex;
}

.nav-links a {
  text-decoration: none;
  color: var(--light-grey);
  font-size: 18px; /* Larger on mobile for better touch targets */
  font-weight: 500;
  letter-spacing: 0.5px;
  transition: color 0.3s ease;
  padding: 12px 0; /* Larger touch target */
}

.nav-links a:hover {
  color: var(--light-green);
}

.nav-buttons {
  display: flex;
  flex-direction: column; /* Stack buttons on mobile */
  gap: 0.75rem;
  width: 100%; /* Full width on mobile */
  padding: 0 1rem;
}

.header-button {
  align-items: center;
  background-image: linear-gradient(144deg, #1B4D3E, #072927 50%, #0A3A34);
  border: 0;
  border-radius: 50px;
  box-shadow: rgba(7, 41, 39, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-family: 'Poppins', sans-serif;
  font-size: 14px; /* Smaller on mobile */
  font-weight: 600;
  justify-content: center;
  line-height: 1em;
  width: 100%; /* Full width on mobile */
  padding: 12px 16px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s ease;
}

.header-button:active,
.header-button:hover {
  outline: 0;
  background-image: linear-gradient(144deg, #2C7A62, #0D4038 50%, #155751);
}

.header-button.secondary {
  background-image: none;
  background-color: transparent;
  border: 2px solid #FFFFFF;
}

.header-button.secondary:hover,
.header-button.secondary:active {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Tablet breakpoint */
@media (min-width: 768px) {
  .header {
    top: 12px;
    left: 12px;
    right: 12px;
  }

  .header-content {
    padding: 0.25rem 1.5rem;
  }

  .logo {
    height: 48px;
  }

  .logo img {
    height: 40px;
  }

  .menu-toggle {
    display: none; /* Hide on tablet and up */
  }

  .nav-links {
    position: static;
    display: flex;
    flex-direction: row;
    background-color: transparent;
    padding: 0;
  }

  .nav-links a {
    font-size: 16px;
    padding: 0;
  }

  .nav-buttons {
    flex-direction: row;
    width: auto;
    padding: 0;
  }

  .header-button {
    width: auto;
    min-width: 120px;
  }
}

/* Desktop breakpoint */
@media (min-width: 1024px) {
  .header {
    top: 16px;
    left: 16px;
    right: 16px;
  }

  .header-content {
    padding: 0.25rem 2rem;
  }

  .logo {
    height: 64px;
  }

  .logo img {
    height: 56px;
  }

  .nav-links {
    gap: 2rem;
  }

  .header-button {
    font-size: 16px;
    min-width: 140px;
    padding: 12px 20px;
  }
}
