@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(135deg, #072927 0%, #0A3D3A 100%);
}

.main-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Changed from center to flex-start */
  padding: 20px;
  background: #072927;
}

.with-header-space {
  padding-top: 80px; /* Adjust this value as needed */
}

.form-container {
  width: 100%;
  max-width: 800px;
  padding: 40px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  text-align: center;
}

.form-logo {
  width: 120px;
  margin-bottom: 24px;
}

h1 {
  color: #FFFFFF;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 8px;
}

p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  margin-bottom: 32px;
}

.demo-form {
  text-align: left;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-bottom: 24px;
}

.form-group {
  margin-bottom: 24px;
}

label {
  display: block;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

input,
textarea {
  width: 100%;
  padding: 12px 16px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 14px;
  transition: all 0.3s ease;
}

input:focus,
textarea:focus {
  outline: none;
  border-color: rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.15);
}

textarea {
  min-height: 120px;
  resize: vertical;
}

.submit-button {
  width: 100%;
  padding: 16px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover {
  background: rgba(255, 255, 255, 0.2);
}

.submit-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.success-message {
  color: #4CAF50;
  margin-top: 16px;
}

.error-message {
  color: #F44336;
  margin-top: 16px;
}

@media (max-width: 768px) {
  .form-row {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .form-container {
    padding: 24px;
  }
}