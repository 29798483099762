@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

:root {
  --primary-bg: #072927;
  --primary-text: #FFFFFF;
  --accent-color: #DFFFBF;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

.hero {
  background-color: var(--primary-bg);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 16px; /* Reduced padding for mobile */
  box-sizing: border-box;
  /* Account for mobile browser navigation bars */
  min-height: -webkit-fill-available;
  height: 100vh;
}

.hero-content {
  text-align: center;
  max-width: 800px;
  padding: 0 8px;
  margin-top: -48px; /* Offset for mobile headers */
}

.hero-title {
  font-size: 32px; /* Base mobile size */
  font-weight: 700;
  color: var(--primary-text);
  line-height: 1.2;
  letter-spacing: 0.5px; /* Reduced for mobile */
  margin-bottom: 16px;
}

.hero-subtitle {
  font-size: 16px;
  font-weight: 400;
  color: #DFFFBF;
  line-height: 1.5;
  letter-spacing: 0.25px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; /* Allow wrapping on mobile */
  gap: 8px; /* Space between wrapped items */
}

.mouse-icon {
  color: var(--primary-text);
  font-size: 16px;
  margin: 0 6px; /* Reduced margins */
  display: inline-flex;
  align-items: center;
}

.hero-buttons {
  display: flex;
  flex-direction: column; /* Stack buttons on mobile */
  gap: 12px;
  padding: 0 16px;
}

.hero-button {
  align-items: center;
  background-image: linear-gradient(144deg, #1B4D3E, #072927 50%, #0A3A34);
  border: 0;
  border-radius: 50px;
  box-shadow: rgba(7, 41, 39, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-family: 'Poppins', sans-serif;
  font-size: 16px; /* Slightly smaller for mobile */
  font-weight: 600;
  justify-content: center;
  line-height: 1em;
  width: 100%; /* Full width on mobile */
  padding: 14px 20px; /* Slightly reduced padding */
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s ease;
}

.hero-button:active,
.hero-button:hover {
  outline: 0;
  background-image: linear-gradient(144deg, #2C7A62, #0D4038 50%, #155751);
}

.hero-button.secondary {
  background-image: none;
  background-color: transparent;
  border: 2px solid #FFFFFF;
}

.hero-button.secondary:hover,
.hero-button.secondary:active {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Tablet breakpoint */
@media (min-width: 768px) {
  .hero {
    padding: 48px 24px;
  }

  .hero-content {
    margin-top: -32px;
  }

  .hero-title {
    font-size: 48px;
    letter-spacing: 1px;
    margin-bottom: 20px;
  }

  .hero-subtitle {
    font-size: 17px;
    margin-bottom: 28px;
  }

  .hero-buttons {
    flex-direction: row;
    justify-content: center;
    gap: 16px;
    padding: 0;
  }

  .hero-button {
    width: auto;
    min-width: 180px;
    font-size: 16px;
  }

  .mouse-icon {
    font-size: 18px;
    margin: 0 8px;
  }
}

/* Desktop breakpoint */
@media (min-width: 1024px) {
  .hero {
    padding: 60px 20px;
  }

  .hero-title {
    font-size: 70px;
    letter-spacing: 1.5px;
    margin-bottom: 24px;
  }

  .hero-subtitle {
    font-size: 18px;
    margin-bottom: 32px;
  }

  .hero-button {
    font-size: 18px;
    padding: 16px 24px;
    min-width: 200px;
  }

  .mouse-icon {
    font-size: 24px;
    margin: 0 10px;
  }
}

/* Fix for mobile browser height issues */
@supports (-webkit-touch-callout: none) {
  .hero {
    height: -webkit-fill-available;
  }
}
