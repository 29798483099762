:root {
    --primary-color: #007f5f;
    --secondary-color: #00b894;
    --accent-color: #ffd166;
    --text-color: #2d3748;
    --background-color: #f7fafc;
    --white: #ffffff;
    --max-width: 1200px;
    --header-height: 80px;
  }
  
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: 'Inter', sans-serif;
    color: var(--text-color);
    background-color: var(--background-color);
    line-height: 1.6;
  }
  
  .container {
    width: 100%;
    max-width: var(--max-width);
    margin: 0 auto;
    padding: 0 20px;
  }
  
  /* Grid System */
  .grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 30px;
  }
  
  .col-1 { grid-column: span 1; }
  .col-2 { grid-column: span 2; }
  .col-3 { grid-column: span 3; }
  .col-4 { grid-column: span 4; }
  .col-5 { grid-column: span 5; }
  .col-6 { grid-column: span 6; }
  .col-7 { grid-column: span 7; }
  .col-8 { grid-column: span 8; }
  .col-9 { grid-column: span 9; }
  .col-10 { grid-column: span 10; }
  .col-11 { grid-column: span 11; }
  .col-12 { grid-column: span 12; }
  
  /* Typography */
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 20px;
    font-weight: 700;
    line-height: 1.2;
  }
  
  h1 { font-size: 3.5rem; }
  h2 { font-size: 2.5rem; }
  h3 { font-size: 2rem; }
  h4 { font-size: 1.5rem; }
  h5 { font-size: 1.25rem; }
  h6 { font-size: 1rem; }
  
  p {
    margin-bottom: 15px;
  }
  
  /* Buttons */
  .btn {
    display: inline-block;
    padding: 12px 24px;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .btn-primary {
    background-color: var(--primary-color);
    color: var(--white);
  }
  
  .btn-primary:hover {
    background-color: var(--secondary-color);
  }
  
  .btn-secondary {
    background-color: var(--white);
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
  }
  
  .btn-secondary:hover {
    background-color: var(--primary-color);
    color: var(--white);
  }
  
  /* Animations */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes scaleIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .animate-fadeInUp {
    animation: fadeInUp 0.6s ease-out;
  }
  
  .animate-scaleIn {
    animation: scaleIn 0.5s ease-out;
  }
  
  /* Section styles */
  section {
    padding: 100px 0;
  }
  
  .section-title {
    text-align: center;
    margin-bottom: 60px;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .grid {
      grid-template-columns: repeat(6, 1fr);
    }
    
    [class^="col-"] {
      grid-column: span 6;
    }
  }
  
  @media (max-width: 480px) {
    .grid {
      grid-template-columns: repeat(4, 1fr);
    }
    
    [class^="col-"] {
      grid-column: span 4;
    }
  }