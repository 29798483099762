@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital@0;1&family=Poppins:wght@400;600;700&display=swap');

:root {
    --background-color: #072927;
    --card-background: #FFFFFF;
    --popular-card-background: #F0E6FF;
    --second-row-card-background: #E6F0FF;
    --text-color: #1A2E22;
    --accent-color: #1B4D3E;
    --button-color: #0A3A34;
    --hover-color: #155751;
    --title-color: #F0F8F0;
}

.pricing-section {
    font-family: 'Poppins', sans-serif;
    background-color: #072927;
    padding: 80px 20px;
}

.pricing-title {
    font-size: 48px;
    font-weight: 700;
    color: var(--title-color);
    margin-bottom: 20px;
    text-align: center;
}

.pricing-title span {
    font-style: italic;
}

.pricing-subtitle {
    font-size: 18px;
    color: var(--title-color);
    max-width: 600px;
    margin: 0 auto 60px;
    text-align: center;
}

.pricing-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
}

.row-title {
    font-size: 24px;
    font-weight: 600;
    color: var(--title-color);
    margin-bottom: 30px;
    padding-left: 100px;
}

.pricing-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
}

.pricing-card {
    background-color: var(--card-background);
    border-radius: 20px;
    padding: 40px;
    text-align: left;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    position: relative;
    display: flex;
    flex-direction: column;
}

.pricing-card:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.pricing-card.popular {
    background-color: var(--popular-card-background);
}

.popular-badge {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: var(--accent-color);
    color: white;
    font-size: 12px;
    font-weight: 600;
    padding: 5px 10px;
    border-radius: 20px;
}

.plan-title {
    font-size: 24px;
    font-weight: 700;
    color: var(--text-color);
    margin-bottom: 15px;
}

.plan-price {
    font-size: 32px;
    font-weight: 700;
    color: var(--accent-color);
    margin-bottom: 15px;
}

.plan-description {
    font-size: 16px;
    color: var(--text-color);
    margin-bottom: 20px;
}

.feature-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 30px;
    flex-grow: 1;
}

.feature-list li {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    color: var(--text-color);
    margin-bottom: 10px;
}

.check-icon {
    color: var(--accent-color);
    margin-right: 10px;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    margin-top: 2px;
}

.primary-button {
    display: block;
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    background-color: var(--button-color);
    color: white;
    border: none;
    margin-top: auto;
    margin-bottom: 20px;
}

.primary-button:hover {
    background-color: var(--hover-color);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.service-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
}

.service-tag {
    font-size: 12px;
    color: var(--accent-color);
    background-color: transparent;
    border: 1px solid var(--accent-color);
    border-radius: 20px;
    padding: 5px 10px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.service-tag:hover {
    background-color: var(--accent-color);
    color: white;
}

@media (max-width: 1200px) {
    .pricing-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .pricing-section {
        padding: 60px 20px;
    }

    .pricing-title {
        font-size: 36px;
    }

    .pricing-subtitle {
        font-size: 16px;
        margin-bottom: 40px;
    }

    .pricing-grid {
        grid-template-columns: 1fr;
    }

    .pricing-card {
        max-width: 400px;
        margin: 0 auto;
    }

    .row-title {
        text-align: center;
        padding-left: 0;
        font-size: 22px;
    }
}

@media (max-width: 480px) {
    .pricing-section {
        padding: 40px 16px;
    }

    .pricing-title {
        font-size: 32px;
    }

    .pricing-card {
        padding: 24px;
    }

    .plan-title {
        font-size: 20px;
    }

    .plan-price {
        font-size: 28px;
    }
}
