@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital@0;1&family=Poppins:wght@400;600;700&display=swap');

.how-it-works-container {
  background-color: #072927;
  padding: 32px 16px; /* Reduced padding for mobile */
  font-family: 'Poppins', sans-serif;
}

.white-container {
  background-color: white;
  border-radius: 16px; /* Slightly reduced for mobile */
  padding: 32px 20px; /* Reduced padding for mobile */
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

.title-container {
  text-align: center;
  margin-bottom: 40px; /* Reduced margin for mobile */
  position: relative;
  padding: 0 8px;
}

.auto-awesome-icon {
  font-size: 48px !important; /* Smaller for mobile */
  color: #072927;
  margin-bottom: 12px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.main-title {
  font-size: 32px; /* Base size for mobile */
  font-weight: 700;
  margin-bottom: 12px;
  padding: 0 8px;
}

.main-title.cursive {
  font-family: 'Playfair Display', serif;
  font-style: italic;
  font-weight: 400;
}

.main-title::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%; /* Reduced width for mobile */
  height: 3px; /* Slightly thinner */
  background-color: #003366;
  border-radius: 2px;
}

.step-card {
  border-radius: 16px;
  padding: 24px 20px; /* Reduced padding for mobile */
  height: 100%;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  margin-bottom: 24px; /* Added spacing between cards */
}

.step-card:hover {
  transform: translateY(-3px); /* Reduced movement on mobile */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.step-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-icon-container {
  margin-bottom: 20px;
}

.step-icon {
  font-size: 56px !important; /* Smaller for mobile */
  color: #072927;
}

.number-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  padding: 0 8px;
}

.number-icon {
  font-size: 28px !important; /* Smaller for mobile */
  color: #072927;
  margin-right: 12px;
}

.number-divider {
  flex-grow: 1;
  height: 2px; /* Thinner on mobile */
  background-color: #072927;
}

.step-title {
  font-weight: 600 !important;
  margin-bottom: 16px !important;
  color: #000000 !important;
  font-size: 20px !important;
  line-height: 1.3 !important;
  text-align: center;
}

.step-description {
  color: #333333 !important;
  font-size: 14px !important; /* Increased for better readability */
  font-weight: 400 !important;
  line-height: 1.5 !important;
  text-align: center;
  padding: 0 8px;
}

/* Tablet breakpoint */
@media (min-width: 768px) {
  .how-it-works-container {
    padding: 48px 24px;
  }

  .white-container {
    padding: 40px 32px;
    border-radius: 20px;
  }

  .auto-awesome-icon {
    font-size: 60px !important;
  }

  .main-title {
    font-size: 36px;
  }

  .step-card {
    padding: 32px;
  }

  .step-icon {
    font-size: 64px !important;
  }

  .number-icon {
    font-size: 32px !important;
  }

  .step-title {
    font-size: 22px !important;
  }
}

/* Desktop breakpoint */
@media (min-width: 1024px) {
  .how-it-works-container {
    padding: 60px 40px;
  }

  .white-container {
    padding: 64px 48px;
    border-radius: 24px;
  }

  .main-title {
    font-size: 48px;
  }

  .auto-awesome-icon {
    font-size: 80px !important;
    margin-bottom: 16px;
  }

  .main-title::after {
    width: 100%;
    height: 4px;
  }

  .step-card {
    padding: 40px;
    margin-bottom: 0; /* Remove margin on desktop */
  }

  .step-icon {
    font-size: 80px !important;
  }

  .number-icon {
    font-size: 40px !important;
  }

  .step-title {
    font-size: 24px !important;
  }

  .step-description {
    font-size: 16px !important;
  }
}

/* Large Desktop breakpoint */
@media (min-width: 1200px) {
  .white-container {
    padding: 64px 48px;
  }
}
