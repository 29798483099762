@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital@0;1&family=Poppins:wght@400;600;700&display=swap');

.membership-benefits {
  background-color: #072927;
  color: #ffffff;
  padding: 40px 16px; /* Reduced padding for mobile */
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

.title {
  font-size: 32px; /* Smaller for mobile */
  font-weight: 700;
  margin-bottom: 12px;
  padding: 0 8px;
  line-height: 1.2;
}

.cursive {
  font-family: 'Playfair Display', serif;
  font-style: italic;
  font-weight: 400;
}

.subtitle {
  font-size: 14px; /* Increased for better readability */
  margin-bottom: 32px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.5;
  padding: 0 8px;
  color: rgba(255, 255, 255, 0.9);
}

.benefits-grid {
  display: grid;
  grid-template-columns: 1fr; /* Single column on mobile */
  gap: 24px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 8px;
}

.benefit-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 16px;
  background: rgba(255, 255, 255, 0.03); /* Subtle background */
  border-radius: 12px;
  transition: transform 0.3s ease;
}

.benefit-item:hover {
  transform: translateY(-4px);
}

.icon-container {
  width: 64px; /* Smaller on mobile */
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
  background: rgba(223, 255, 191, 0.1); /* Subtle background */
  border-radius: 50%;
  padding: 12px;
}

.benefit-item h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #DFFFBF;
  line-height: 1.3;
}

.benefit-item p {
  font-size: 14px; /* Increased for readability */
  line-height: 1.5;
  max-width: 300px;
  margin: 0 auto;
  color: rgba(255, 255, 255, 0.9);
}

/* Tablet breakpoint */
@media (min-width: 768px) {
  .membership-benefits {
    padding: 60px 24px;
  }

  .title {
    font-size: 36px;
    margin-bottom: 14px;
  }

  .subtitle {
    font-size: 15px;
    margin-bottom: 40px;
  }

  .benefits-grid {
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    gap: 28px;
  }

  .icon-container {
    width: 72px;
    height: 72px;
  }
}

/* Desktop breakpoint */
@media (min-width: 1024px) {
  .membership-benefits {
    padding: 80px 20px;
  }

  .title {
    font-size: 48px;
    margin-bottom: 16px;
  }

  .subtitle {
    font-size: 16px;
    margin-bottom: 48px;
  }

  .benefits-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 32px;
  }

  .benefit-item {
    padding: 20px;
  }

  .icon-container {
    width: 80px;
    height: 80px;
    margin-bottom: 24px;
  }

  .benefit-item h3 {
    font-size: 20px;
    margin-bottom: 16px;
  }
}

/* Reduced motion */
@media (prefers-reduced-motion: reduce) {
  .benefit-item {
    transition: none;
  }
  
  .benefit-item:hover {
    transform: none;
  }
}
