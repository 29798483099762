.footer {
  background-color: #072927;
  color: #F0F8F0;
  padding: 32px 16px 16px;
  font-family: 'Poppins', 'sans-serif';
  position: relative;
  overflow: hidden;
  border-top: 1px solid #F0F8F0;
}

.footer-content {
  display: flex;
  flex-direction: column;
  max-width: 900px;
  margin: 0 auto;
  padding: 0 8px;
}

.footer-columns {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.footer-column {
  width: 100%;
  margin-bottom: 16px;
}

.footer-column h3 {
  font-size: 16px;
  margin-bottom: 16px;
  color: #F0F8F0;
  text-align: center;
}

.footer-column ul {
  list-style-type: none;
  padding: 0;
  text-align: center;
}

.footer-column ul li {
  margin-bottom: 12px;
}

.footer-column ul li a {
  color: #F0F8F0;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;
  padding: 8px 0;
  display: inline-block;
}

.footer-column ul li a:hover {
  color: #DFFFBF;
}

.product-logos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 24px 0;
}

.product-logo {
  max-width: 80px;
  height: auto;
  transition: transform 0.3s ease;
  filter: brightness(0) invert(1); /* Make logos white */
  opacity: 0.9;
}

.product-logo:hover {
  transform: scale(1.1);
  opacity: 1;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin: 24px 0;
}

.social-icons li a {
  color: #F0F8F0;
  font-size: 24px;
  padding: 8px;
}

.contact-info {
  margin-top: 24px;
  text-align: center;
}

.contact-info p {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 12px;
  font-size: 14px;
}

.footer-bottom {
  margin-top: 32px;
  padding-top: 16px;
  border-top: 1px solid #F0F8F0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.footer-logo-bottom img {
  max-width: 100%;
  height: auto;
  margin-bottom: 16px;
  filter: brightness(0) invert(1); /* Make logo white */
}

.footer-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-top: 16px;
}

.footer-links a {
  color: #F0F8F0;
  text-decoration: none;
  font-size: 12px;
  padding: 8px 0;
  margin: 0;
}

#animation-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.icon {
  position: absolute;
  width: 28px;
  height: 28px;
  opacity: 0.7;
  transition: transform 0.3s ease-out;
}

.co2-icon {
  background-size: contain;
  background-repeat: no-repeat;
}

.recycling-icon svg {
  width: 100%;
  height: 100%;
}

/* Tablet breakpoint */
@media (min-width: 768px) {
  .footer {
    padding: 48px 32px 24px;
  }

  .footer-content {
    flex-direction: row;
  }

  .footer-columns {
    flex-direction: row;
    gap: 40px;
  }

  .footer-column {
    margin-bottom: 0;
  }

  .product-logo {
    max-width: 100px;
  }

  .footer-bottom {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }

  .footer-links {
    flex-direction: row;
    gap: 20px;
    margin-top: 0;
  }

  .footer-links a {
    margin: 0;
  }

  .icon {
    width: 36px;
    height: 36px;
  }
}

/* Desktop breakpoint */
@media (min-width: 1024px) {
  .footer {
    padding: 60px 40px 20px;
  }

  .footer-column h3 {
    font-size: 18px;
  }

  .footer-logo-bottom img {
    max-width: 750px;
  }
}

/* Handle reduced motion preference */
@media (prefers-reduced-motion: reduce) {
  .product-logo {
    transition: none;
  }

  .product-logo:hover {
    transform: none;
  }
}
